import React, { FC } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import { Badge, BadgeColor } from '@components/uikit/Badge/Badge';
import { StepRow, StepRowProps } from '@components/uikit/StepRow/StepRow';
import { useNavigate } from 'react-router-dom';

type BadgeType = {
	text: string;
	color?: BadgeColor;
	classes?: string;
};

export type RecognitionHeaderProps = {
	title?: string;
	badges?: BadgeType[];
	steps?: StepRowProps['steps'];
	onStepClick?: StepRowProps['onStepClick'];
};

export const StepsHeader: FC<RecognitionHeaderProps> = ({ title, badges = [], steps, onStepClick }) => {
	const navigate = useNavigate();

	const onBackClick = () => {
		navigate(-1);
	};

	return (
		<div className={'flex flex-col gap-y-[40px] w-full'}>
			<section className={'flex justify-between items-center pb-[24px] border-b border-b-neutral-90'}>
				<button
					className={'flex gap-x-[8px] font-title-large text-secondary p-[12px_24px_12px_0]'}
					onClick={onBackClick}
				>
					<Icon name={'arrowLeftShort'} width={24} height={24} />
					Назад
				</button>

				<div className="flex gap-2">
					{badges.map((item) => {
						return (
							<Badge
								size={'lg'}
								variant="squared"
								color={item.color ?? 'secondary'}
								key={item.text}
								className={item.classes}
							>
								{item.text}
							</Badge>
						);
					})}
				</div>
			</section>
			<h1 className={'font-display-small text-on-background mt-[-8px] mb-[-16px]'}>{title}</h1>
			{steps && <StepRow steps={steps} onStepClick={onStepClick} />}
		</div>
	);
};
