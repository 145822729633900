import { useGetBucketConfigQuery } from '@store/api';
import { eventsSelector } from '@store/slices/eventsSlice';
import { useStoreSelector } from '@store/store';
import dayjs from 'dayjs';

type UseDateEventsFinderProps = {
	bucket_id?: string;
	type: string;
	startDate?: string;
	endDate?: string;
};

const ARCHIVE_TIME = dayjs().hour(0).minute(0).second(0).millisecond(0).toISOString();

export const useDateEventsFinder = ({ bucket_id, type, startDate, endDate }: UseDateEventsFinderProps) => {
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucket_id ?? '', { skip: !bucket_id });
	const { max_ts } = useStoreSelector(eventsSelector);

	const isActiveBucket = () => {
		if (!bucketConfig) return { isActive: !bucketConfig, endTime: '' };

		const duration = Number(
			bucketConfig.tags
				.find((item) => {
					return item.includes('duration');
				})
				?.replace('duration:', ''),
		);
		const endTime = dayjs(bucketConfig.timestamp_created)
			.add(duration ?? 0, 'hour')
			.toISOString();
		return {
			isActive: !duration || Date.parse(endTime) > Date.now(),
			duration,
			endTime,
		};
	};

	const isActive = isActiveBucket();

	const timeCalculate = () => {
		if (!bucketConfig) {
			return ARCHIVE_TIME;
		}

		const moveToArchive = Number(
			bucketConfig.tags
				.find((item) => {
					return item.includes('archiveMoving');
				})
				?.replace('archiveMoving:', ''),
		);

		if (!moveToArchive) {
			return ARCHIVE_TIME;
		}

		const createdTime = bucketConfig.timestamp_created;
		const hoursAmountPassedTotal = (Date.now() - Date.parse(createdTime)) / 1000 / 3600;
		const hoursAmountToArchivePoint = Math.floor(hoursAmountPassedTotal / moveToArchive) * moveToArchive;

		return isActive.isActive
			? dayjs(createdTime).add(hoursAmountToArchivePoint, 'hour').toISOString()
			: isActive.endTime;
	};

	const minTime = () => {
		if (type === 'new') {
			const date = timeCalculate();

			if (startDate) {
				return Date.parse(startDate) > Date.parse(date) ? new Date(startDate).toISOString() : date;
			}

			return date;
		}

		return startDate ? new Date(startDate).toISOString() : startDate;
	};

	const maxTime = () => {
		if (type === 'new') {
			const date = isActive.isActive ? max_ts ?? undefined : timeCalculate();

			if (endDate) {
				return !date || Date.parse(endDate) < Date.parse(date) ? new Date(endDate).toISOString() : date;
			}
			return date;
		}

		const date = timeCalculate();
		if (endDate) {
			return Date.parse(endDate) < Date.parse(date) ? new Date(endDate).toISOString() : date;
		}

		return date;
	};

	return { minTime: minTime(), maxTime: maxTime(), isActive: isActive.isActive };
};
