import React from 'react';
import { UseMonitoringReturnType } from '@components/camera/Monitoring/useMonitoring';
import classes from './Monitoring.module.css';
import { MonitoringSlot } from '@components/camera/Monitoring/MonitoringSlot/MonitoringSlot/MonitoringSlot';
import SimplePaginator from '@components/uikit/SimplePaginator/SimplePaginator';

type MonitoringProps = {
	onChangeTarget?: React.ComponentProps<typeof MonitoringSlot>['onTargetChange'];
	monitoring: UseMonitoringReturnType;
};

/**
 * Компонента мониторинга камер с возможностью выбора вида отображения.
 * Изменение вида отображения снаружи возможно посредством useMonitoringCache.
 * @constructor
 */
export const Monitoring = ({
	onChangeTarget,
	monitoring: { grid, gridList, actions, camerasList, page },
}: MonitoringProps) => {
	const containerClassName = [classes[`grid-${grid}`], classes['container']].join(' ');

	const isHighQuality = (idx: number) => {
		switch (grid) {
			case 1:
				return true;
			case 4:
				return true;
			case 8:
			case 9:
				return idx === 0;
			case 10:
				return idx === 0 || idx === grid - 1;
			default:
				return false;
		}
	};

	return (
		<div className={'flex flex-col gap-4'}>
			<div className={'relative '}>
				<div className={containerClassName}>
					{gridList.map((camera, idx) => {
						const key = `${camera?.id ?? 'no-camera'}-${idx}`;

						return (
							<MonitoringSlot
								showHighQuality={isHighQuality(idx)}
								camera={camera}
								key={key}
								onChange={(cameraId) => {
									actions.changeCameraAtPosition(idx, cameraId);
								}}
								onTargetChange={onChangeTarget}
								camerasList={camerasList}
							/>
						);
					})}
				</div>
			</div>
			<div className={'relative flex justify-end'}>
				<SimplePaginator onRightClick={actions.nextPage} onLeftClick={actions.prevPage} page={page} />
			</div>
		</div>
	);
};

export const MemoizedMonitoring = React.memo(Monitoring);
