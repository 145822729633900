import React, { FC } from 'react';
import { ROUTER } from '@constants/router';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { IconButton } from '@components/uikit/Button/IconButton';
import { monitoringSelector, monitoringSlice } from '@store/slices/monitoringSlice';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { MemoizedMonitoring } from '@components/camera/Monitoring/Monitoring';
import { EventPreviewImage } from '@components/event/EventPreviewImage/EventPreviewImage';
import { EventFilterWrapper } from '@components/event/EventFilter/EventFilterWrapper';
import { MonitoringGridBarWrapper } from '@components/camera/Monitoring/MonitoringGridBar/MonitoringGridBarWrapper';
import { EventTypeSelect } from '@components/uikit/Select/EventTypeSelect/EventTypeSelect';
import { QueryTab } from '@components/uikit/Tab/QueryTab';
import { useInitialSearchParams } from '@hooks/useInitialSearchParams';
import { useSearchParams } from 'react-router-dom';
import { MonitoringEventsZoneList } from '@components/camera/Monitoring/MonitoringEventsList/MonitoringEventsZoneList';
import { MonitoringEventsLineList } from '@components/camera/Monitoring/MonitoringEventsList/MonitoringEventsLineList';
import { useMonitoring } from '@components/camera/Monitoring/useMonitoring';

export const MonitoringPage: FC = () => {
	useInitialSearchParams({
		detectionType: 'zones',
	});
	const [sectionParams] = useSearchParams();
	const section = sectionParams.get('detectionType') ?? '';

	const dispatch = useStoreDispatch();
	const { isEventCollapse } = useStoreSelector(monitoringSelector);

	const onEventCollapse = () => {
		dispatch(monitoringSlice.actions.toggleCollapse());
	};

	const monitoring = useMonitoring();

	return (
		<DashboardLayout>
			<DashboardContentLayout
				headerContentClassName={''}
				title={ROUTER.MONITORING.TITLE}
				showBackButton={false}
				drawDivider={false}
				headerContent={
					<div className="flex gap-3">
						<div className={'flex gap-8'}>
							<div className={'flex items-center gap-[20px]'}>
								<p className={'font-title-large text-primary-0 break-normal'}>Активные камеры</p>
								<MonitoringGridBarWrapper />
							</div>
						</div>

						<div className={'flex bg-primary-95 p-2 rounded-3 gap-1 w-max ml-auto'}>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'zones'}>
								Зоны детекции
							</QueryTab>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'lines'}>
								Линии детекции
							</QueryTab>
						</div>
					</div>
				}
			>
				<div className={'absolute bottom-[24px]'} style={{ zIndex: 999 }}>
					<IconButton
						className={'relative left-[24px]'}
						onClick={onEventCollapse}
						style={{ borderRadius: '100px', rotate: isEventCollapse ? '180deg' : undefined }}
						icon={'chevronCompactDoubleLeft'}
					/>
				</div>

				<div className={'flex gap-[48px]'}>
					<section
						className={`${
							isEventCollapse ? 'hidden' : ''
						} flex flex-col gap-y-[20px] p-4 bg-neutral-98 rounded-2 overflow-y-auto w-[40%] max-w-[800px] min-w-[650px]`}
					>
						<div className="flex gap-2">
							<EventTypeSelect containerClassName="mr-auto min-w-[240px]" />
							<div className="flex gap-2">
								<EventFilterWrapper />
							</div>
						</div>
						{section === 'zones' && <MonitoringEventsZoneList grid={monitoring.grid} gridList={monitoring.gridList} />}
						{section === 'lines' && <MonitoringEventsLineList />}
					</section>

					<div className={'grow h-fit sticky top-[24px]'}>
						<EventPreviewImage />
						<MemoizedMonitoring monitoring={monitoring} />
					</div>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
