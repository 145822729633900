import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { Modal } from '@components/uikit/Modal/Modal';
import { ROUTER } from '@constants/router';
import { notify } from '@modules/toast/notify';
import { FC } from 'react';

type EventCopyLinkModalProps = {
	isOpen: boolean;
	eventId: number;
	title?: string;
	onClose: () => void;
};

export const EventCopyLinkModal: FC<EventCopyLinkModalProps> = ({ eventId, title, isOpen, onClose }) => {
	const createLink = () => {
		const query = new URLSearchParams();
		query.set('faceId', eventId.toString());
		return `${new URL(window.location.origin + ROUTER.EVENTS.PATH).toString()}?${query}`;
	};

	return (
		<Modal isOpen={isOpen} title="Выберите способ" onClose={onClose} className={'!overflow-visible'}>
			<div className="w-[300px] flex flex-col gap-y-3">
				<Button
					className="w-full"
					tooltip={
						'Если кнопка не работает, проверьте, проставлено ли "Enabled" в настройках браузера по адресу chrome://flags/#web-share пункт "Web Share"'
					}
					onClick={(event) => {
						event.stopPropagation();
						navigator.share &&
							navigator.share({
								title: title,
								url: createLink(),
							});
					}}
				>
					<Icon name="share" />
					Поделиться
				</Button>

				<Button
					className="w-full"
					onClick={(event) => {
						event.stopPropagation();
						navigator.clipboard &&
							navigator.clipboard.writeText(createLink()).then(() => {
								notify('Скопировано');
								onClose();
							});
					}}
				>
					<Icon name="folder" />
					Скопировать в буфер обмена
				</Button>
			</div>
		</Modal>
	);
};
