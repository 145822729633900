import {
	BucketMatch,
	BucketMatchPlate,
	FaceCameraEvent,
	LprEventData,
	api,
	useGetBucketFacesQuery,
	useGetBucketPlatesQuery,
	useGetCamerasByBucketQuery,
	useGetCamerasQuery,
	useGetEventsByCamerasQuery,
	useLazyFindInArchivePlateParamsQuery,
	useLazyFindInBaseQuery,
} from '@store/api';
import { useEffect, useMemo } from 'react';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { StoreEvent, eventsSelector, eventsSlice } from '@store/slices/eventsSlice';
import { useSearchParams } from 'react-router-dom';
import { personMapper } from '@modules/events/personMapper';
import { plateMapper } from '@modules/events/plateMapper';
import { isBefore } from 'date-fns';
import { eventsListSlice } from '@store/slices/eventListSlice';
import { useDateEventsFinder } from './useDateEventsFinder';
import { useEventsFilter } from './useEventsFilter';
import { useWebSocketEvents } from './useWebSocketEvents';
import { filterParamsSectionMapper } from '@utils/filterParamsSectionMapper';
import { useEventsParams } from './useEventsParams';

export type EventDataFace = {
	bucket_matches: {
		face_id: number;
		bucket_name: string;
		similarity: number;
		identity: string;
		tags: string[];
	}[];
	timestamp: string;
	timestamp_rfc?: string;
	timestamp_local: string;
	camera_name: string;
	camera_uuid: string;
	quality?: number;
	age?: number | null;
	gender?: string | null;
	glasses?: string | null;
	facial_hair?: string | null;
	mask?: string | null;
	race?: string | null;
	overview_bbox?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export type EventDataPlate = {
	bucket_matches: {
		bucket_name: string;
		similarity: number;
		identity: string;
		tags: string[];
	}[];
	camera_uuid: string;
	camera_name: string;
	overview_link?: string;
	plate_link?: string;
	vehicle_link?: string;
	vehicle_track?: number[];
	track_id?: string;
	timestamp: string;
	timestamp_rfc?: string;
	timestamp_local: string;
	overview_bbox: {
		x: number;
		y: number;
		width: number;
		height: number;
	};
	plate?: {
		text: string;
		quality: number;
		occlusion: number;
		valid: boolean;
		country: string;
		country_iso_alpha2: string;
		country_iso_alpha3: string;
		category: string;
	};
	vehicle?: {
		category: string | null;
		purpose: string | null;
		color: string | null;
		visibility: number | null;
	};
};

export type Match = {
	type: 'face';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	quality?: number;
	age?: number;
	gender?: string;
	glasses?: string;
	facial_hair?: string;
	mask?: string;
	race?: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export type MatchPlate = {
	type: 'plate';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	occlusion?: number;
	valid?: boolean;
	quality?: number;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
	plate?: string;
	country?: string;
	countryCode?: string;
	category?: string;
	vehicle?: {
		category: string;
		purpose: string;
		color: string;
		visibility?: number;
	};
};

const SIMILARITY = 0.8;

/**
 * Хук содержит логику для работы со списком событий на странице "События"
 */

export const useEventsPage = () => {
	const dispatch = useStoreDispatch();
	const [searchParams] = useSearchParams();
	const { filterParams, filterFacesHandler, filterPlatesHandler } = useEventsFilter();
	const { onEventTypeChange } = useEventsParams();

	const bucket_id = searchParams.get('bucket_id') ?? undefined;
	const faceId = searchParams.get('faceId') ?? undefined;
	const plateId = searchParams.get('plateId') ?? undefined;
	const max_ts = useStoreSelector((store) => {
		return store.events.max_ts;
	});

	const { maxTime, minTime, isActive } = useDateEventsFinder({
		bucket_id,
		type: filterParams.type,
		startDate: filterParams.startDate,
		endDate: filterParams.endDate,
	});

	const offset = useStoreSelector((store) => {
		return store.events.offset;
	});

	const { currentData: camerasData } = useGetCamerasByBucketQuery(bucket_id ?? '', { skip: bucket_id === undefined });

	//Иначе не работают события с сокетов, так что в случае когда нет выбраных камер на стр событий приходится пробегаться по ним ну или я чего то не понимаю
	const { currentData: allCameras } = useGetCamerasQuery({});

	//Для фильтрации событий приходящих по сокетам
	const { currentData: facesFromBucket } = useGetBucketFacesQuery(bucket_id ?? '', { skip: bucket_id === undefined });
	const { currentData: platesFromBucket } = useGetBucketPlatesQuery(bucket_id ?? '', { skip: bucket_id === undefined });

	const [findFaceInBase] = useLazyFindInBaseQuery();
	const [findPlateInBase] = useLazyFindInArchivePlateParamsQuery();

	const camerasId: string[] = useMemo(() => {
		if (camerasData?.cameras) {
			return camerasData.cameras.map((camera) => {
				return camera.camera_uuid;
			});
		}
		return (allCameras?.data || []).map((camera) => {
			return camera.camera_uuid;
		});
	}, [camerasData, allCameras]);

	useEffect(() => {
		return () => {
			dispatch(eventsListSlice.actions.clearEvents());
			dispatch(eventsSlice.actions.clearEvents());
		};
	}, [bucket_id, dispatch]);

	const { currentData } = useGetEventsByCamerasQuery(
		{
			camera: camerasId.join(','),
			offset: offset,
			limit: 10,
			sort: 'desc',
			min_ts: minTime,
			max_ts: maxTime,
			include_boundaries: false,
			type: filterParamsSectionMapper(filterParams.section),
		},
		//cameras.length приходится пихать чтоб первый запрос не дублировался (см коммент выше)
		{ skip: bucket_id !== undefined || !camerasId.length || faceId !== undefined || plateId !== undefined },
	);

	const faces = facesFromBucket?.faces ?? [];
	const plates = platesFromBucket?.plates ?? [];

	const onBucketFaceOrPlateHandler = (bucket_matches: BucketMatch[] | BucketMatchPlate[]) => {
		if (!bucket_id) {
			return true;
		} else {
			return bucket_matches.some((bucket) => {
				return bucket.bucket_name === bucket_id;
			});
		}
	};

	const onFaceHandler = async (face_id: number, isNew = false) => {
		const { data } = await dispatch(api.endpoints.getEventDataFace.initiate(face_id));
		if (data && filterFacesHandler(data) && onBucketFaceOrPlateHandler(data.bucket_matches)) {
			const event = personMapper(data, face_id, bucket_id);
			dispatch(eventsListSlice.actions.addEvent(event));
			isNew && dispatch(eventsSlice.actions.incNewEvents());
			return event;
		}
	};

	const onPlateHandler = async (plate_id: number, isNew = false) => {
		const { data } = await dispatch(api.endpoints.getEventDataPlate.initiate(plate_id));
		if (data && filterPlatesHandler(data) && onBucketFaceOrPlateHandler(data.bucket_matches)) {
			const plate = plateMapper(data, plate_id, bucket_id);
			dispatch(eventsListSlice.actions.addEvent(plate));
			isNew && dispatch(eventsSlice.actions.incNewEvents());
			return plate;
		}
	};

	useEffect(() => {
		if (camerasData === undefined || (facesFromBucket === undefined && platesFromBucket === undefined)) {
			return;
		}

		const fetch = async () => {
			const [facesData, platesData] = await Promise.all([
				Promise.all(
					faces.map(async ({ face_id }) => {
						const { data } = await findFaceInBase({
							bucket_name: bucket_id ?? '',
							face_id: face_id,
							threshold: SIMILARITY,
							limit: 100,
							sort_key: '-timestamp',
							camera: camerasId,
							min_ts: minTime,
							max_ts: maxTime,
						});

						return { ...data };
					}),
				),
				Promise.all(
					plates.map(async ({ plate_text }) => {
						const { data } = await findPlateInBase({
							plate_text: plate_text,
							camera: camerasId,
							min_ts: minTime,
							max_ts: maxTime,
						});

						return { ...data };
					}),
				),
			]).finally(() => {
				//Нет пагинации на методах, соответствено нет инфинити скролла, поэтому для того чтоб лоадер не крутился постоянно
				dispatch(eventsSlice.actions.setTotal(1));
			});

			const eventsFace = facesData
				.flatMap((face) => {
					return face.history_matches ?? [];
				})
				.sort((a, b) => {
					return isBefore(new Date(a.timestamp), new Date(b.timestamp)) ? 1 : -1;
				});

			const eventsPlate = platesData.flatMap((plate) => {
				return plate.data ?? [];
			});

			eventsPlate.forEach(async ({ plate_id }) => {
				onPlateHandler(plate_id);
			});
			eventsFace.forEach(async ({ face_id }) => {
				onFaceHandler(face_id);
			});
		};

		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facesFromBucket, platesFromBucket, camerasData, filterParams.type]);

	const syncEffect = async () => {
		if (faceId !== undefined) {
			const face = await onFaceHandler(Number(faceId));
			dispatch(eventsSlice.actions.setaActiveEventId(Number(faceId)));
			dispatch(eventsSlice.actions.setaActiveEvent(face));
			dispatch(eventsSlice.actions.setMaxTs(face?.timestamp ?? ''));
			onEventTypeChange({ type: 'old' });
		}
		if (plateId !== undefined) {
			const plate = await onPlateHandler(Number(plateId));
			dispatch(eventsSlice.actions.setaActiveEventId(Number(plateId)));
			dispatch(eventsSlice.actions.setaActiveEvent(plate));
			dispatch(eventsSlice.actions.setMaxTs(plate?.timestamp ?? ''));
			onEventTypeChange({ type: 'old' });
		}
	};

	useEffect(() => {
		syncEffect();
		return () => {
			dispatch(eventsListSlice.actions.clearEvents());
			dispatch(eventsSlice.actions.clearEvents());
		};
		// eslint-disable-next-line
	}, [faceId, plateId]);

	useEffect(() => {
		dispatch(eventsListSlice.actions.clearEvents());
		dispatch(eventsSlice.actions.clearEvents());
		syncEffect();
		// eslint-disable-next-line
	}, [filterParams, camerasId]);

	const { trackIds } = useStoreSelector(eventsSelector);

	useEffect(() => {
		if (currentData === undefined) {
			return;
		}

		dispatch(eventsSlice.actions.setTotal(currentData.total));

		const dataIds: string[] = [...trackIds];
		const filteredData = () => {
			const data = currentData.data.reduce<(FaceCameraEvent | LprEventData)[]>((acc, item, i) => {
				if (i === 0 && !dataIds.includes(item.track_id)) {
					dataIds.push(item.track_id);
					return [...acc, item];
				}

				if (!dataIds.includes(item.track_id)) {
					dataIds.push(item.track_id);
					return [...acc, item];
				}

				return acc;
			}, []);

			return data;
		};

		const events = filteredData();

		if (events.length === 0) {
			dispatch(eventsSlice.actions.incOffset());
			return;
		}

		const eventsQuenue = events.reduce<StoreEvent[]>((queue, event) => {
			if ('plate_id' in event && ['', 'plates'].includes(filterParams.section) && filterPlatesHandler(event)) {
				queue.push(plateMapper(event, event.plate_id));
			}

			if ('face_id' in event && ['', 'faces'].includes(filterParams.section) && filterFacesHandler(event)) {
				queue.push(personMapper(event, event.face_id));
			}

			return queue;
		}, []);

		if (max_ts === undefined && currentData?.max_ts !== undefined) {
			dispatch(eventsSlice.actions.setMaxTs(currentData?.max_ts));
		}

		if (eventsQuenue.length > 0) {
			dispatch(eventsListSlice.actions.addEvents(eventsQuenue));
		} else {
			setTimeout(() => {
				dispatch(eventsSlice.actions.incOffset());
			}, 100);
		}
		dispatch(eventsSlice.actions.addTrackId(dataIds));
		// eslint-disable-next-line
	}, [currentData, dispatch, filterParams]);

	useWebSocketEvents(
		camerasId,
		filterParams.type !== 'old' && isActive && !filterParams.endDate && !faceId && !plateId,
		filterParams.section,
		onFaceHandler,
		onPlateHandler,
	);
};
