import React, { FC, useId, useRef, useState } from 'react';
import { FramePlaceholder } from '@components/uikit/FramePlaceholder/FramePlaceholder';
import { PersonExtra } from '@components/event/EventSettings/PersonExtra/PersonExtra';
import { IconTextRow } from '@components/uikit/IconTextRow/IconTextRow';
import { useGetFaceImageOverviewQuery } from '@store/api';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { numWord } from '@utils/numWord';
import { Border } from '@components/uikit/Border/Border';
import { Tooltip } from 'react-tooltip';
import { dateParser } from '@utils/dateParser';
import { EventCopyLinkModal } from '../EventCopyLinkModal/EventCopyLinkModal';

export type EventSettingsType = {
	eventId: number;
	watched?: Nullable<boolean>;
	falsy?: Nullable<boolean>;
	comment?: Nullable<string>;
	title?: string;
	configTitle?: string;
	target?: string;
	targetId?: string;
	location?: string;
	camera?: string;
	cameraId?: string;
};

export type EventSettingsProps = {
	imageUrl?: Nullable<string>;
	eventId: number;
	watched?: Nullable<boolean>;
	falsy?: Nullable<boolean>;
	comment?: Nullable<string>;
	title?: string;
	configTitle?: string;
	target?: string;
	targetId?: string;
	location?: string;
	camera?: string;
	cameraId?: string;
	onCommentChange?: (comment: string) => void;
	extra?: Nullable<React.ComponentProps<typeof PersonExtra>['extra']>;

	age?: number;
	gender?: string;
	glasses?: string;
	facial_hair?: string;
	mask?: string;
	race?: string;
	date: string;
	dateLocal: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export const EventSettings: FC<EventSettingsProps> = ({
	eventId,
	imageUrl,
	camera,
	configTitle,
	title,
	onCommentChange,
	extra,
	box,
	age,
	gender,
	glasses,
	facial_hair,
	mask,
	race,
	date,
	dateLocal,
}) => {
	const { gmtLocal, dateStr, timeStr, timeStrLocal } = dateParser(date, dateLocal);

	const imgRef = useRef<HTMLImageElement>(null);
	const cameraNameId = useId();
	const timeId = useId();

	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="ml-8 flex flex-col gap-y-[20px]">
			{imageUrl ? (
				<div className={'relative'}>
					<img className={'w-[749px] h-[500px] rounded-[8px] object-fill'} src={imageUrl} ref={imgRef} alt="example" />
					<Border box={box} ref={imgRef} />
				</div>
			) : (
				<FramePlaceholder className={'!w-[749px] !h-[500px]'} />
			)}
			<div className={'flex flex-col gap-y-[16px]'}>
				<div className={'flex items-start justify-between'}>
					<div className={'flex flex-col items-start justify-center gap-y-2'}>
						<div className={'flex items-center gap-x-2'}>
							<h2 className={'text-on-primary-container w-[216px] font-headline-small truncate'}>{title}</h2>
						</div>
						<div className={'flex gap-x-2'}>
							<IconTextRow
								size="l"
								className={'text-on-secondary-container opacity-50 !gap-x-[4px] '}
								icon={'calendarAll'}
							>
								{dateStr}
							</IconTextRow>
							<IconTextRow size="l" className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'clock'}>
								{timeStrLocal}
							</IconTextRow>
							<div
								id={timeId}
								className="text-on-secondary-container hover:text-primary hover:opacity-100 opacity-50 transition-all"
							>
								<span>(GMT{gmtLocal <= 0 ? `+${Math.abs(gmtLocal)}` : -gmtLocal})</span>

								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(timeId)}`}
									place={'top'}
									content={`По московскому времени: ${timeStr}`}
								/>
							</div>
							<div id={cameraNameId}>
								<IconTextRow
									size="l"
									className={'opacity-50'}
									classNameText={'w-[300px] truncate'}
									icon={'cameraVideo'}
								>
									{camera}
								</IconTextRow>
								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(cameraNameId)}`}
									place={'top'}
									content={camera}
								/>
							</div>
						</div>
					</div>

					<Button
						onClick={() => {
							setIsOpen(true);
						}}
					>
						<Icon name="share" />
						Поделиться
					</Button>
				</div>

				{configTitle && (
					<div className={'grid grid-cols-2 gap-2'}>
						<IconTextRow className={'order-2'} icon={'boundingBox'}>
							{configTitle}
						</IconTextRow>
					</div>
				)}

				<div className={'grid grid-cols-2 w-[550px] grid-flow-row gap-y-1 '}>
					{age && (
						<p>
							<span className="font-medium">Возраст:</span> {`${age} ${numWord(age, ['год', 'года', 'лет'])}`}
						</p>
					)}
					<p>
						<span className="font-medium">Пол:</span> {gender}
					</p>

					<p>
						<span className="font-medium">Раса:</span> {race}
					</p>
					<p>
						<span className="font-medium">Маска:</span> {mask}
					</p>

					<p>
						<span className="font-medium">Наличие очков:</span> {glasses}
					</p>
					<p>
						<span className="font-medium">Растительность:</span> {facial_hair}
					</p>
				</div>

				<PersonExtra extra={extra} />

				<span className={'h-[1px] w-full bg-neutralVariant-80'}></span>
			</div>

			<EventCopyLinkModal
				eventId={eventId}
				title={title}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
			/>
		</div>
	);
};

export const EventSettingsWithImage: FC<Omit<EventSettingsProps, 'imageUrl'>> = (props) => {
	const { currentData: imageUrl } = useGetFaceImageOverviewQuery(props.eventId);
	const newProps = { ...props, imageUrl };
	return <EventSettings {...newProps} />;
};
