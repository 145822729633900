import { EventDateTimeWrapper } from '@components/event/EventDateTime/EventDateTimeWrapper';
import { EventFilterWrapper } from '@components/event/EventFilter/EventFilterWrapper';
import { EventTypeSelect } from '@components/uikit/Select/EventTypeSelect/EventTypeSelect';
import { ReactComponent as Detector } from '@assets/illustrations/detector.svg';

export const EventsOverviewLine = () => {
	return (
		<div className={'flex flex-col gap-y-[32px] flex-grow h-full'}>
			<div className={'flex h-full'}>
				<section className={'flex flex-col gap-y-[20px] flex-grow p-4 bg-neutral-98 rounded-2 min-w-[782px]'}>
					<div className="flex gap-2">
						<EventTypeSelect containerClassName="mr-auto min-w-[240px]" />
						<div className="flex gap-2">
							<EventDateTimeWrapper />
							<EventFilterWrapper />
						</div>
					</div>
					<div
						className={
							'w-full h-[calc(100vh-20px-32px*4-46px-50px)] flex flex-col gap-y-[32px] items-center justify-center'
						}
					>
						<Detector />
						<p style={{ wordBreak: 'break-word' }} className={'font-headline-medium '}>
							В данный момент события отсутствуют
						</p>
					</div>
				</section>
				<section className={'flex flex-col min-w-fit h-fit sticky top-[24px]'}></section>
			</div>
		</div>
	);
};
