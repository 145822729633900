import React, { FC } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import { PowerButton } from '@components/uikit/Button/PowerButton';
import { FavoriteIcon } from '@components/uikit/Icon/FavoriteIcon';
import { ReactComponent as CameraPlaceholder } from '@assets/illustrations/camera-placeholder.svg';
import { CameraIndicator, CameraIndicatorProps } from '@components/camera/CameraIndicator/CameraIndicator';
import { getTextWithDeclination } from '@utils/getTextWithDeclination';

import styles from '../CameraPreview.module.css';
import useOutsideClick from '@hooks/useOutsideClick';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { Button } from '@components/uikit/Button/Button';
import { ROUTER } from '@constants/router';
import { Link } from 'react-router-dom';

export type CameraPreviewProps = {
	id: string;
	configCount: number;
	imageUrl: string;
	name: string;
	status: CameraIndicatorProps['status'];
	target: string;
	location: string;
	favorite?: boolean;
	enabled?: boolean;
	tags: string[];
	source: string;
	isDisabled: boolean;
	quality_threshold?: number;
	timestamp_created: string;
	type: string;
	isEditable: boolean;
	onFavoriteToggle: (id: string, tags: string[]) => void;
	onEnabledToggle?: () => void;
};

export const CameraPreviewZone: FC<CameraPreviewProps> = ({
	id,
	configCount,
	imageUrl,
	name,
	status,
	type,
	isDisabled,
	tags,
	favorite,
	timestamp_created,
	isEditable,
	onFavoriteToggle,
	onEnabledToggle,
}) => {
	const { isOpen, setIsOpen, ref } = useOutsideClick();
	return (
		<div className={'p-[16px] rounded-[8px] bg-secondary-container flex gap-x-8  border border-primary-80'}>
			<div className={'w-[195px] h-[110px] text-center relative'}>
				{imageUrl ? (
					imageUrl !== 'initializating' ? (
						<>
							<img
								className={'w-[195px] h-[110px] rounded-[4px] object-cover max-w-[unset]'}
								src={imageUrl}
								alt={'Изображение камеры'}
							/>
							<div ref={ref}>
								<button
									className={'absolute bottom-[3px] right-[6px] opacity-0 transition-opacity hover:opacity-100'}
									onClick={() => {
										setIsOpen((prev) => {
											return !prev;
										});
									}}
								>
									<Icon className={'text-primary-100'} name={'aspectRatio'} width={24} height={24} />
								</button>

								{isOpen && (
									<div
										className={styles['img-full']}
										onClick={() => {
											setIsOpen(false);
										}}
									>
										<img src={imageUrl} alt={'Изображение камеры'} />
									</div>
								)}
							</div>
						</>
					) : (
						<div className="relative h-full  flex items-end justify-center ">
							<Spinner />
							<p className="break-normal font-body-medium m-2">Камера инициализируется</p>
						</div>
					)
				) : (
					<div className={'w-[195px] h-[110px] flex justify-center items-center'}>
						<CameraPlaceholder />
					</div>
				)}
			</div>
			<div className={'flex flex-col gap-y-2 py-[18px] justify-between'}>
				<div className={'flex gap-x-3 items-center'}>
					<h2
						className={
							'font-headline-medium text-on-background min-w-[208px] text-left 2xl:max-w-[500px] xl:max-w-[380px] max-w-[300px] truncate'
						}
					>
						{name}
					</h2>
					<CameraIndicator status={status} />
				</div>
				<div className={'flex gap-x-6 items-center'}>
					{type && (
						<p className={'font-body-large text-on-background flex gap-x-2 items-center capitalize'}>
							<Icon name={'aspectRatio'} width={20} height={20} />
							{type}
						</p>
					)}
					<p className={'font-body-large text-on-background flex gap-x-2 items-center'}>
						<Icon name={'boundingBox'} width={20} height={20} />
						{getTextWithDeclination(configCount, ['конфигурация', 'конфигурации', 'конфигураций'])}
					</p>

					{timestamp_created && (
						<p className="flex gap-x-2 items-center">
							<Icon name={'calendar'} width={20} height={20} />
							{new Date(timestamp_created).toLocaleDateString()}
						</p>
					)}
				</div>
			</div>
			<div className={'ml-auto flex flex-col justify-between items-end relative z-10'}>
				<button
					onClick={() => {
						onFavoriteToggle(id, tags);
					}}
				>
					<FavoriteIcon active={favorite} />
				</button>

				<div className={'flex gap-3'}>
					<Link to={ROUTER.CAMERAS_CREATE_ZONE.PATH + `?id=${id}`}>
						<Button outline={true} variant="grey">
							Редактировать камеру
						</Button>
					</Link>

					<PowerButton
						disabled={!isEditable}
						on={isDisabled}
						onClick={() => {
							onEnabledToggle && onEnabledToggle();
						}}
					/>
				</div>
			</div>
		</div>
	);
};
