import React, { FC, useId } from 'react';
import classes from './Button.module.css';
import { Tooltip } from 'react-tooltip';

type ButtonVariant = 'primary' | 'grey' | 'dark' | 'transparent' | 'error' | 'red';

type ButtonSize = 'base' | 'xs' | 'sm' | 'l' | 'xl';

export type ButtonProps = JSX.IntrinsicElements['button'] & {
	outline?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
	tooltip?: string;
};

export const Button: FC<ButtonProps> = ({
	outline = false,
	variant = 'primary',
	size = 'base',
	className: propClassName,
	tooltip,
	...propsProxy
}) => {
	const classList = [classes['button'], classes[variant], classes[size], propClassName];
	outline && classList.push(classes['outlined']);
	const className = classList.filter(Boolean).join(' ').trim();

	const tooltipId = useId();

	return (
		<>
			<Tooltip className={'z-50 !rounded-2'} anchorSelect={`#${CSS.escape(tooltipId)}`} place={'top'} content={tooltip}>
				{tooltip}
			</Tooltip>
			<button id={tooltipId} {...propsProxy} className={className} />
		</>
	);
};
