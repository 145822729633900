import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Popover } from '@components/uikit/Popover/Popover';
import { Icon } from '@components/uikit/Icon/Icon';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { replaceLetters } from '@utils/lisensePlateMapper';
import { EnToRuMap } from '@constants/lisenseLetters';
import { Button } from '../Button/Button';
import classes from './LicensePlateInput.module.css';
import Flag from 'react-world-flags';
import { LicensePlateInputWrapper } from './LisensePlateInputByCountries/LisensePlateInputWrapper';

export type NumberType = 'car' | 'taxi' | 'trailer' | 'motorcycle';
export type CountryType = 'rus' | 'by' | 'ua' | 'kz' | 'other';

function containsOnlyAllowedCharacters(str: string, allowedCharacters: string[]) {
	// Преобразуем строку в массив символов
	const strArray = str.split('');

	// Проверяем каждый символ в массиве
	for (const char of strArray) {
		// Если символ не входит в список разрешенных символов, вернем false
		if (!allowedCharacters.includes(char)) {
			return false;
		}
	}

	// Если все символы в строке входят в список разрешенных символов, вернем true
	return true;
}

export type LicensePlateInputProps = {
	value?: string;
	onChange?: (value: string) => void;
	numberType?: NumberType;
	onNumberTypeChange?: (numberType: NumberType) => void;
	numberCountry?: CountryType;
	onCountryChange?: (countryType: CountryType) => void;
};

const countrySettings = ['rus', 'by', 'ua', 'kz', 'other'] as const;

const numberTypeSettings = {
	car: {
		name: 'Автомобильный номер',
		type: 'car',
	},
	taxi: {
		name: 'Такси, автобусы',
		type: 'taxi',
	},
	trailer: {
		name: 'Прицепы',
		type: 'trailer',
	},
	motorcycle: {
		name: 'Мотоциклы, тракторы',
		type: 'motorcycle',
	},
} as const;

/**
 * Компонент ввода номера автомобиля
 * @param value - значение номера автомобиля (например, 'a123bc193')
 * @param onChange - функция, которая вызывается при изменении номера автомобиля
 * @param numberType - тип номера автомобиля (например, 'car')
 * @param onNumberTypeChange - функция, которая вызывается при изменении типа номера автомобиля
 * @param onCountryChange - функция, которая вызывается при изменении страны номера автомобиля
 */
export const LicensePlateInput: FC<LicensePlateInputProps> = ({
	value,
	onChange,
	numberType,
	onNumberTypeChange,
	numberCountry,
	onCountryChange,
}) => {
	useEffect(() => {
		if (!value) {
			setNumber('');
			setRegion('');
			return;
		}

		if (!value?.match(/^[a-z*]{1}[0-9*]{3}[a-z*]{2}[0-9*]{2,3}/i)) {
			return;
		}

		setNumber(value?.slice(0, 6) || '');
		setRegion(value?.slice(6) || '');
	}, [value, numberCountry]);

	const [number, setNumber] = useState('');
	const [region, setRegion] = useState('');

	const [numberError, setNumberError] = useState('');
	const numberUid = useMemo(() => {
		return 'license-plate-input-number-' + uuidv4();
	}, []);

	const [regionError, setRegionError] = useState('');
	const regionUid = useMemo(() => {
		return 'license-plate-input-region' + uuidv4();
	}, []);

	const changeNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.toUpperCase();
		const transformedValue = replaceLetters(value, EnToRuMap);

		const allowedCharacters = [...Object.keys(EnToRuMap), '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*'];
		if (!containsOnlyAllowedCharacters(transformedValue, allowedCharacters)) {
			setNumberError('Используйте только те символы, которые используются в номерах такого типа!');
			return;
		}
		setNumberError('');

		setNumber(transformedValue);
		emitChange(transformedValue, region);
	};

	const changeRegion = (e: ChangeEvent<HTMLInputElement>) => {
		const allowedCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*'];

		if (numberCountry === 'ua') {
			const value = e.target.value.toUpperCase();
			const transformedValue = replaceLetters(value, EnToRuMap);
			const allowedCharacters = [...Object.keys(EnToRuMap), '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*'];

			if (!containsOnlyAllowedCharacters(transformedValue, allowedCharacters)) {
				setNumberError('Используйте только те символы, которые используются в номерах такого типа!');
				return;
			}

			setRegionError('');

			setRegion(transformedValue);
			emitChange(number, transformedValue);
		} else {
			if (!containsOnlyAllowedCharacters(e.target.value, allowedCharacters)) {
				setRegionError('Используйте только цифры!');
				return;
			}
			setRegionError('');

			setRegion(e.target.value);
			emitChange(number, e.target.value);
		}
	};

	const emitChange = (number: string, region: string) => {
		if (numberCountry === 'ua' || (numberCountry === 'by' && numberType === 'taxi')) {
			onChange?.(`${region}${number}`);
		} else {
			onChange?.(`${number}${region}`);
		}
	};

	const [popoverTypeOpen, setPopoverTypeOpen] = useState(false);
	const [popoverCountryOpen, setPopoverCountryOpen] = useState(false);

	return (
		<div className={'flex flex-col gap-y-[16px]'}>
			<Popover
				positions={['bottom']}
				containerClassName={'rounded-[8px] p-[12px] pl-[4px] box-shadow-elevation-4 border border-secondary-90'}
				isOpen={popoverTypeOpen}
				onClose={setPopoverTypeOpen.bind(null, false)}
				content={
					<div className={'flex flex-col gap-y-[12px]'}>
						<Toggle
							type={'radio'}
							label={'Автомобильный номер'}
							onChange={() => {
								setPopoverTypeOpen(false);
								onNumberTypeChange && onNumberTypeChange('car');
							}}
							checked={numberType === 'car'}
						/>
						<Toggle
							type={'radio'}
							label={'Такси, автобусы'}
							onChange={() => {
								setPopoverTypeOpen(false);
								onNumberTypeChange && onNumberTypeChange('taxi');
							}}
							checked={numberType === 'taxi'}
						/>
						<Toggle
							type={'radio'}
							label={'Прицепы'}
							onChange={() => {
								setPopoverTypeOpen(false);
								onNumberTypeChange && onNumberTypeChange('trailer');
							}}
							checked={numberType === 'trailer'}
						/>
						<Toggle
							type={'radio'}
							label={'Мотоциклы, тракторы'}
							onChange={() => {
								setPopoverTypeOpen(false);
								onNumberTypeChange && onNumberTypeChange('motorcycle');
							}}
							checked={numberType === 'motorcycle'}
						/>
					</div>
				}
			>
				{() => {
					const buttonClassName =
						'flex gap-x-[8px] items-center font-headline-xsmall ' +
						(popoverTypeOpen ? 'text-primary' : 'text-on-background');

					return (
						<button className={buttonClassName} onClick={setPopoverTypeOpen.bind(null, !popoverTypeOpen)}>
							{numberTypeSettings[numberType || 'car'].name}
							<Icon
								name={'chevronCompactDown'}
								width={24}
								height={24}
								style={{
									transform: popoverTypeOpen ? 'rotate(180deg)' : 'rotate(0deg)',
									transition: 'transform 0.12s ease',
								}}
							/>
						</button>
					);
				}}
			</Popover>

			<div className="flex gap-2">
				<LicensePlateInputWrapper
					number={number}
					changeNumber={changeNumber}
					numberUid={numberUid}
					numberError={numberError}
					region={region}
					changeRegion={changeRegion}
					regionUid={regionUid}
					regionError={regionError}
					numberType={numberType}
					numberCountry={numberCountry}
				/>

				<Popover
					positions={['bottom', 'left']}
					align={'end'}
					isOpen={popoverCountryOpen}
					shouldCloseOnClickOutside={() => {
						setPopoverCountryOpen(false);
						return true;
					}}
					content={
						<div className={'flex flex-col py-2 w-[155px] border border-outline-variant rounded-2'}>
							{countrySettings.map((item) => {
								return (
									<div
										key={item}
										onClick={() => {
											onCountryChange && onCountryChange(item);
											setPopoverCountryOpen(false);
										}}
										className={
											'uppercase flex items-center gap-2 font-title-large p-2 hover:bg-primary-90 transition-colors cursor-pointer'
										}
									>
										{item === 'other' && <div className={'w-[34px] h-[20px] bg-primary-60	'}></div>}
										<Flag code={item} height={24} width={34} />
										{item}
									</div>
								);
							})}
						</div>
					}
				>
					{() => {
						return (
							<Button
								variant={'transparent'}
								className={`${classes['country__btn']} ${
									popoverCountryOpen ? 'outline !outline-1 !outline-primary-50' : ''
								}`}
								outline={true}
								size={'xl'}
								onClick={() => {
									setPopoverCountryOpen(!popoverCountryOpen);
								}}
							>
								{numberCountry === 'other' && <div className={'w-[34px] h-[20px] bg-primary-60	flex-shrink-0'}></div>}
								<Flag code={numberCountry} height={24} width={34} />
								{numberCountry}
								<Icon
									name={'chevronCompactDown'}
									className={popoverCountryOpen ? 'rotate-180 flex-shrink-0' : 'flex-shrink-0'}
									width={24}
									height={24}
								/>
							</Button>
						);
					}}
				</Popover>
			</div>
		</div>
	);
};
