import { FC } from 'react';
import { Events } from '@components/events/Events';
import { useMonitoringEventsPage } from '@hooks/useMonitoringEventsPage';
import { GridList } from '../useMonitoring';
import { MonitoringGrid } from '../Monitoring.typedef';

export const MonitoringEventsZoneList: FC<{ gridList: GridList; grid: MonitoringGrid }> = ({ gridList, grid }) => {
	const cameras = gridList.reduce<string[]>((acc, item, i) => {
		if (grid - 1 >= i) {
			return typeof item?.id === 'string' ? [...acc, item.id] : acc;
		}
		return acc;
	}, []);

	useMonitoringEventsPage({
		camerasId: cameras,
	});

	return <Events />;
};
